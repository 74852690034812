











import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class FortnoxSetupBeforeAuthPage extends Vue {
  private clickProceed() {
    this.$router.push({
      name: 'ChooseSite',
      query: {
        redirect: '/settings/economy/accounting-integration/setup/overview?type=Fortnox&install=create',
      },
    })
  }
}
